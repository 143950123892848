@import "theme-variables";
@import "breakpoints";

.content-layout-dialog.p-dynamic-dialog {
  width: 58rem;
  border-radius: $border-radius;
  position: relative;
  overflow: hidden;

  @include respond-to(lower-than-large-screens) {
    width: 43rem;
  }

  @include respond-to(lower-than-small-screens) {
    width: 21.5rem;
    border-radius: 1rem;
  }

  .p-dialog-content {
    border-radius: $border-radius;
    padding: 3.75rem 7.5rem;

    @include respond-to(lower-than-large-screens) {
      padding: 3.75rem 2rem;
    }

    @include respond-to(lower-than-small-screens) {
      padding: 2rem 1.5rem;
      border-radius: 1rem;
    }
  }
}
