@import "breakpoints";

p-progressspinner {
  .p-progress-spinner {
    .p-progress-spinner-circle {
      stroke: #fff;
      animation: unset;
    }

    @include respond-to(lower-than-medium-screens) {
      width: 4rem;
      height: 4rem;
    }
  }
}
