@import "theme-variables";
@import "breakpoints";

.confirmation-dialog.p-dynamic-dialog {
  width: 35rem;
  border-radius: $border-radius;
  overflow: hidden;

  @include respond-to(lower-than-small-screens) {
    width: 19.5rem;
    border-radius: 1rem;
  }

  .p-dialog-content {
    padding: 3.5rem;

    @include respond-to(lower-than-small-screens) {
      padding: 2rem 1.5rem;
    }
  }
}
