@import "theme-variables";

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover,
.p-togglebutton.p-button:focus.p-highlight {
  background: $primary;
  border-color: $primary;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: $secondary-text-color;
}

.p-togglebutton.p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
}
