@import "theme-variables";

.p-menu {
  padding: unset;
  width: fit-content;

  .p-menuitem.highlight .p-menuitem-link {
    .p-menuitem-icon,
    .p-menuitem-text {
      @include font-bold;
    }
  }
}
