@import "theme-variables";

.p-tieredmenu {
  width: auto;
  max-width: 12rem;
  padding: 0;
  border-radius: 0.25rem;

  .p-submenu-list {
    padding: inherit;
    border-radius: 0.25rem;
  }

  .highlight span {
    @include font-bold;
  }

  .p-menuitem .p-menuitem-link {
    .p-menuitem-icon {
      @include font-bold;

      font-size: 20px;
    }

    .p-menuitem-icon,
    .p-menuitem-text {
      color: $primary-text-color !important;
    }
  }
}
