@import "theme-variables";
@import "breakpoints";

.p-tooltip {
  max-width: 26rem;

  @include respond-to(lower-than-large-screens) {
    max-width: 20rem;
  }

  @include respond-to(lower-than-small-screens) {
    max-width: 10rem;
  }

  .p-tooltip-text {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: $primary-text-color;
  }
}
