@import "theme-variables";

.custom-scrollbar,
.p-datatable-wrapper,
.p-multiselect-label {
  /* width */
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-light;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $gray;
  }
}
