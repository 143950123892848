@import "breakpoints";

@mixin hover-effect($effect) {
  @media (hover: hover) and (pointer: fine) {
    @if $effect != "text" {
      transition: all 0.3s;
    }

    &:hover {
      cursor: pointer;

      @if $effect== "zoom" {
        transform: scale(1.05);
      } @else if $effect== "text" {
        -webkit-text-stroke: 1px $primary;
        color: $primary !important;
      } @else if $effect== "background" {
        background-color: $primary !important;
        color: $white !important;
      }
    }
  }
}

@mixin center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin sticky {
  position: sticky;
  z-index: 1;
}

@mixin fade-out-element($start, $end, $direction: to right) {
  mask-image: linear-gradient($direction, transparent $start, black $end);
}

@mixin fade-out-text() {
  background: linear-gradient(
    90deg,
    currentcolor calc(100% - 20px),
    rgb(0 0 0 / 10%)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin content-layout-padding {
  padding-left: 8.5rem;
  padding-right: 8.5rem;

  @include respond-to(lower-than-extra-large-screens) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @include respond-to(lower-than-large-screens) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @include respond-to(lower-than-small-screens) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@mixin link-btn {
  height: 2.5rem;
  text-decoration: unset;
  color: $primary-text-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2rem;
  padding: 0 1rem;
  font-size: 1rem;
  transition: all 0.3s;
  background-color: $white;
  box-shadow: $secondary-shadow;
  cursor: pointer;
  @include hover-effect("zoom");
  @include hover-effect("background");

  svg-icon {
    transition: color 0.3s;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover svg-icon {
      color: $white;
    }
  }
}

@mixin ellipsis-with-lines($lines) {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin guideline-message-style {
  font-size: 1rem;
  font-style: italic;
  color: $gray;
}

@mixin content-layout {
  @include content-layout-x;
  @include content-layout-top;
  @include content-layout-bottom;
}

@mixin content-layout-x {
  margin-left: 8.5rem;
  margin-right: 8.5rem;

  @include respond-to(lower-than-extra-large-screens) {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  @include respond-to(lower-than-large-screens) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @include respond-to(lower-than-medium-screens) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@mixin content-layout-top {
  margin-top: 3.75rem;

  @include respond-to(lower-than-extra-large-screens) {
    margin-top: 2rem;
  }
}

@mixin content-layout-bottom {
  margin-bottom: 3.75rem;
}

@mixin responsible-persons {
  @include content-layout-bottom;

  padding: 0 4rem;

  @include respond-to(lower-than-extra-large-screens) {
    padding: 0;
  }

  @include respond-to(lower-than-small-screens) {
    padding: 0 1rem;
  }
}

@mixin field-readonly {
  background-color: $background;
  border: 2px solid #fff;
}

@mixin required-field {
  label.required::after {
    content: "*";
    color: $danger;
    position: relative;
    left: -4px;
  }
}
