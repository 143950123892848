@import "theme-variables";
@import "breakpoints";

svg-icon {
  display: flex;
  color: $white;
  background-color: $primary-light;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 0.3rem;

  &.stroked {
    background-color: unset;
    color: $primary;
  }

  &.icon-medium {
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
  }

  &.icon-large {
    width: 55px;
    height: 55px;
    padding: 0.7rem;

    @include respond-to(lower-than-small-screens) {
      width: 50px;
      height: 50px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
