@import "theme-variables";

$hidden: $danger;
$published: #717744;

.status {
  p-tag {
    border-radius: 0;

    span {
      @include font-medium;

      color: $secondary-text-color;
      border-radius: 0;
      padding: 0;

      .p-tag-value {
        display: none;
      }
    }

    .published-tag {
      background-color: $published;

      span {
        padding: 0.25rem 0.4rem;
      }
    }

    .hidden-tag {
      background-color: $hidden;

      span {
        padding: 0.25rem 0.4rem;
      }
    }
  }
}
