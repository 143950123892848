@import "theme-variables";
@import "mixins";

.p-button {
  display: unset;
  padding: 0.5rem;

  &.p-button-icon-only.p-button-rounded {
    @include center-items;

    width: 24px;
    height: 24px;
    padding: unset;
    transition: opacity 0.3s;

    &:enabled,
    &.p-button-outlined:enabled {
      color: $primary;
      background: transparent;

      &:focus,
      &:hover,
      &:active {
        color: $primary;
        background: transparent;
      }
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .p-button-label {
    flex: unset;
  }

  &.required .p-button-label::after {
    content: "*";
    color: $danger;
  }
}

button {
  .p-button-icon {
    font-size: 1.4rem;
  }
}
