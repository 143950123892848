/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme-variables";

// Primeng components
@import "~primeng/resources/primeng.min.css";

// Primeng Flex Grid
@import "~primeflex/primeflex";

// Primeng Icons
@import "~primeicons/primeicons.css";

// Override Primeng components
@import "primeng/p-input-text";
@import "primeng/p-form-layout";
@import "primeng/p-button";
@import "primeng/p-menu";
@import "primeng/p-component";
@import "primeng/p-toast";
@import "primeng/p-paginator";
@import "primeng/p-sidebar";
@import "primeng/p-toggle-button";
@import "primeng/p-tooltip";
@import "primeng/p-tiered-menu";
@import "primeng/p-dropdown";
@import "primeng/p-multiselect";
@import "primeng/p-editor";
@import "primeng/p-textarea";
@import "primeng/p-tab-view";
@import "primeng/p-file-upload";
@import "primeng/p-divider";
@import "primeng/p-card";
@import "primeng/p-progress-spinner";
@import "primeng/p-toggle-switch";
@import "primeng/p-carousel";
@import "primeng/p-checkbox";
@import "primeng/p-chips";

// Override app components
@import "app/header-profile";
@import "app/sidebar";
@import "app/table";
@import "app/content-layout-dialog";
@import "app/confirmation-dialog";
@import "app/dialog-content-layout";
@import "app/paginator";
@import "app/content-category-form";
@import "app/status-tag";
@import "app/process-step-form";
@import "app/svg-icon";
@import "app/custom-scrollbar";

// Theme customization
@import "theme";

// global app styles
