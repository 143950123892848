@import "theme-variables";

.p-checkbox {
  box-shadow: unset !important;

  .p-checkbox-box {
    border-radius: 50%;
    border: 1px solid $primary !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background-color: $primary;
}

.p-checkbox-label {
  cursor: pointer;
}
