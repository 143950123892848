@import "theme-variables";
@import "breakpoints";

.p-carousel {
  .p-carousel-content {
    overflow: visible;
  }

  .p-carousel-items-content {
    padding-bottom: 2rem;
    padding-top: 2rem;

    .p-carousel-item {
      flex-grow: 0 !important;
    }
  }

  .p-carousel-content .p-carousel-prev {
    @include respond-to(lower-than-extra-large-screens) {
      width: 1.5rem;
      height: 1.5rem;
    }

    @include respond-to(lower-than-small-screens) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .p-carousel-content .p-carousel-next {
    @include respond-to(lower-than-extra-large-screens) {
      width: 1.5rem;
      height: 1.5rem;
    }

    @include respond-to(lower-than-small-screens) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .p-carousel-content .p-carousel-prev,
  .p-carousel-content .p-carousel-next {
    color: $white;
    background-color: $primary-lighter;

    .pi {
      line-height: unset;
      font-size: 0.7rem;
    }

    &:enabled:hover {
      color: $white;
      background-color: $primary;
    }
  }
}
