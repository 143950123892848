@import "theme-variables";
@import "breakpoints";

.p-paginator {
  background: unset;
  border: unset;
  font-family: $root-font-family;
  padding: 0.5rem 0;

  .p-paginator-first {
    margin-left: auto;
  }

  .p-paginator-pages .p-paginator-page,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    height: 2rem;
    min-width: 2rem;
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    &,
    &:not(.p-disabled):not(.p-highlight):hover {
      color: $primary;
    }
  }

  .p-paginator-pages .p-paginator-page {
    background: $white;

    &,
    &:not(.p-highlight):hover {
      color: $primary;
    }

    &.p-highlight {
      color: $white;
      background: $primary;

      @include respond-to(lower-than-small-screens) {
        padding: 0.5rem 0;
      }
    }
  }
}
