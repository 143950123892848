@import "theme-variables";
@import "mixins";

.p-dropdown {
  border: none;
  width: 100%;
  height: $input-height;
  border-radius: $input-border-radius;
  box-shadow: $secondary-shadow;

  &.p-disabled {
    z-index: 1; // To fix the box-shadow issue on safari
  }

  &:not(.p-disabled).p-focus {
    box-shadow: $secondary-shadow, $focus-shadow;
  }

  &:not(.p-disabled):hover {
    border-color: unset;
  }

  .p-dropdown-label {
    width: 0;

    &.p-placeholder {
      color: $placeholder-color;
    }
  }

  &.primary {
    background-color: $primary;
    box-shadow: $secondary-shadow, inset 0 0 0 1px $white;

    .p-dropdown-label {
      color: $white;
    }
  }

  &.ng-dirty.ng-invalid &.ng-dirty.ng-invalid {
    background-color: $danger !important;
  }

  .p-inputtext {
    padding: $input-padding;
    align-self: center;
  }

  .p-dropdown-trigger {
    width: 2.875rem;

    .fa-solid.fa-angle-down {
      @include center-items;

      background-color: $primary-light;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      font-size: 0.7rem;
      color: $secondary-text-color;
    }
  }
}

.p-dropdown-panel {
  border-radius: $input-border-radius;
  overflow: hidden;
  box-shadow: $secondary-shadow;
  width: inherit;
  max-width: 0;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }

  .p-dropdown-header {
    padding: 0;

    .p-inputtext:enabled:focus {
      box-shadow: unset;
    }

    .p-dropdown-filter-icon {
      display: none;
    }
  }

  .p-dropdown-items .p-dropdown-item {
    @include text-truncate;

    &.p-highlight {
      color: inherit;
      background: $primary-light;
    }
  }
}

.p-dropdown-panel.search-box__dropdown {
  border-radius: 0.25rem;
  width: auto;
  max-width: 12rem;
}
