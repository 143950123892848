@import "theme-variables";

p-divider {
  display: flex;
}

.p-divider.p-divider-horizontal {
  &.p-divider-dashed::before {
    border: none;
    height: 1px;
    background-image: linear-gradient(
      to right,
      $gray-light 0.6rem,
      transparent 1px
    );
    background-size: 1rem 1rem;
  }

  &::before {
    border-top: 1px $gray-light;
  }
}
